import React, {useState} from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import PT_Calculo from './routes/pt/calculo/pt_calculo';
import ES_Calculo from './routes/es/calculo/es_calculo';
import ErroPagina from './routes/erropagina';
import Documentos from './routes/documentos';


export function AppRoutes({
    temParametros,
    dataCarros,
    nomeGeradoDaProposta,
    isDarkTheme,
    setIsDarkTheme,
    currentLanguage,
}) {

    const [valoresProposta, setValoresProposta] = useState([]);


    function formatarNumero(number, decimalPlaces = 0, thousandsSeparator = ' ', decimalSeparator = '.') {

        number = parseFloat(number);

        if (number < 10000) {
        }

        const formattedValue = number.toLocaleString('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });

        // SEPARAR NAS DECIMAIS
        let parts = formattedValue.split('.');

        let integerPart = parts[0];
        if (thousandsSeparator === ' ') {
            integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(",", ' ');
        }

        let decimalPart = parts.length > 1 ? decimalSeparator + `${parts[1]}` : '';
        if (thousandsSeparator === '.') {
            decimalPart = parts.length > 1 ? `.${parts[1]}` : '';
        }


        return `${integerPart}${decimalPart}`;
    }


    return (
        <Routes>

            <Route
                path="/pt/simulador"
                element={
                    <PT_Calculo
                        currentLanguage={currentLanguage}
                        dataCarros={dataCarros}
                        temParametros={temParametros}
                        formatarNumero={formatarNumero}
                        nomeGeradoDaProposta={nomeGeradoDaProposta}
                        isDarkTheme={isDarkTheme}
                        setIsDarkTheme={setIsDarkTheme}
                        valoresProposta={valoresProposta}
                        setValoresProposta={setValoresProposta}
                    />
                } />


            <Route
                path="/es/simulador"
                element={
                    <ES_Calculo
                        currentLanguage={currentLanguage}
                        dataCarros={dataCarros}
                        temParametros={temParametros}
                        formatarNumero={formatarNumero}
                        nomeGeradoDaProposta={nomeGeradoDaProposta}
                        isDarkTheme={isDarkTheme}
                        setIsDarkTheme={setIsDarkTheme}
                    />
                } />


            <Route
                path="/pt/docs"
                element={
                    // <div className={`${isDarkTheme ? "light-theme" : ""} max-w-screen-xl m-auto`}>
                        <div className={`w-full flex flex-col justify-center items-center gap-4 p-2 h-screen`}>
                            <div className={`w-full flex flex-col gap-2 md:gap-4 text-white`}>
                                <div className='h-screen p-2 md:p-10 content-center'>
                                    <Documentos
                                        currentLanguage={currentLanguage}
                                        dataCarros={dataCarros}
                                        temParametros={temParametros}
                                        formatarNumero={formatarNumero}
                                        nomeGeradoDaProposta={nomeGeradoDaProposta}
                                        isDarkTheme={isDarkTheme}
                                        setIsDarkTheme={setIsDarkTheme}
                                    />
                                </div>
                            </div>
                        </div>
                    // </div>
                }
            />



            <Route path="*" element={
                <ErroPagina isDarkTheme={isDarkTheme} />
            } />

        </Routes>
    )
}
