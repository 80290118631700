import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import queryString from 'query-string';
import { IoMdCloseCircle } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo-branco 2.svg';
import { FaCheckCircle, FaClock } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';


const { Option } = Select;

export default function Documentos({ }) {


    const location = useLocation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true); // Armazenar o token de acesso

    const [data, setData] = useState(''); // Armazenar o token de acesso

    const [tipoDeNegocio, setTipoDeNegocio] = useState(''); // Armazenar o token de acesso

    const [key, setKey] = useState(''); // Armazenar o token de acesso

    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    // const [formEnviadoSucesso, setFormEnviadoSucesso] = useState(''); //estado para usar o form


    const [tipoDocumento, setTipoDocumento] = useState([]); // Armazenar o token de acesso
    const [estadosDocumentos, setEstadosDocumentos] = useState([]); // Armazenar o token de acesso
    const [status, SetStatus] = useState(''); // Armazenar o token de acesso





    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const keyFromUrl = parsed.token_acesso;
        // console.log(keyFromUrl)
        if (keyFromUrl) {

            setKey(keyFromUrl);

            let dados = {
                token_acesso: keyFromUrl
            }

            // Fazer o pedido GET para obter dados dos documentos
            axios.get(`${process.env.REACT_APP_GET_PROPOSTA_PT}`, { params: dados })
                .then(response => {
                    if (response.data.sucesso) {
                        const data = response?.data;
                        console.log(data)
                        if (data) {

                            setData(data);
                            console.log('asdsa')
                            const tipoNegocio = data?.documentacao?.info; // Corrigido para buscar a informação correta
                            setTipoDeNegocio(tipoNegocio);
                            // setFormEnviadoSucesso(data?.documentacao?.info?.status); // Garantido que está buscando o dado correto

                            console.log('asdsa')
                            //status
                            SetStatus(data?.documentacao?.info.status)

                            //estados rejetiados
                            const estadosRejeitados = data?.documentacao?.estado_documentos?.filter(doc => doc.status === "rejected") ?? [];
                            console.log(estadosRejeitados);
                            setEstadosDocumentos(estadosRejeitados);

                            console.log('asdsa')
                            // Verificando a existência de 'tipoDeNegocio' antes de acessar propriedades
                            const contrato = tipoNegocio?.tipo_contrato;

                            // Setando tipos de documentos do contrato, garantindo que a estrutura está correta
                            const tiposDocumentos = data?.documentacao?.tipos_documentos?.[contrato];
                            console.log(tiposDocumentos)
                            setTipoDocumento(tiposDocumentos);
                            console.log('asdsa')
                        }
                        console.log('asdsa')
                        setLoading(false)
                    } else {
                        message.error(response.data.mensagem);
                        setLoading(false)
                    }
                    // console.log(response)

                })
                .catch(error => {
                    // console.error('Erro ao obter dados dos documentos:', error);
                });
        }

    }, [location.search]);


    const handleSubmit = (values) => {
        console.log('Valores do formulário:', values);

        const formData = new FormData();

        formData.append('tipo_documento_identificacao', values.tipoDocumento || '');
        formData.append('certidao_permanente', values.certidaoPermanente || '');

        // Mude o processamento para aceitar múltiplos arquivos
        if (values.documentoIdentificacao?.fileList) {
            console.log(values.documentoIdentificacao)
            values.documentoIdentificacao.fileList.forEach(file => {
                formData.append('documento_identificacao[]', file.originFileObj);
            });
        }

        if (values.documentoConducao?.fileList) {
            values.documentoConducao.fileList.forEach(file => {
                formData.append('documento_conducao[]', file.originFileObj);
            });
        }

        if (tipoDeNegocio.tipo_contrato !== "empresa" && values.documentoMorada?.fileList) {
            // if (!values.documentoMorada || values.documentoMorada.fileList.length === 0) {
            //     message.error('Por favor faça upload do documento de morada.');
            //     return;
            // }
            values.documentoMorada.fileList.forEach(file => {
                formData.append('documento_morada[]', file.originFileObj);
            });
        }

        formData.append('token_acesso', key);

        // console.log([...formData.entries()]);  // Para verificar os valores adicionados ao FormData

        axios.post(`${process.env.REACT_APP_UPLOUD_DOCUMENTOS}`, formData)
            .then(response => {
                console.log(response)
                if (response.data.sucesso) {
                    message.success('Documentos enviados com sucesso!');
                    SetStatus(response.data.documentacao_enviada);
                } else {
                    message.error(response.data.mensagem);
                    SetStatus('pending');
                }
            })
            .catch(error => {
                console.error('Erro ao enviar documentos:', error);
                message.error('Erro, contacte o responsável!');
            });
    };



    console.log('tipoDocumento', tipoDocumento)


    return (
        <>
            {loading ? (
                <></>
            ) : (
                <>
                    <div className={`${isMobile ? 'flex flex-col justify-center gap-4' : 'grid grid-cols-10'}`}>
                        <div className={`flex flex-col items-center ${isMobile ? '' : 'col-span-6 items-baseline justify-between gap-6'}`}>
                            <a href='https://importrust.com/' target='_blank'>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="w-40 max-w-40 h-10 object-contain cursor-pointer"

                                />
                            </a>
                            {!isMobile && (
                                <>
                                    <div>
                                        <h2 className="text-2xl md:text-4xl font-bold">Especialistas em<br></br> importação automóvel</h2>
                                    </div>
                                    <div className="flex flex-col gap-5">
                                        <div className="flex flex-row gap-3">
                                            <div className='card'>
                                                <h3 className="text-xl md:text-2xl font-semibold">+2000</h3>
                                                <span className="text-xs">Clientes Satisfeitos</span>
                                            </div>
                                            <div className='card'>
                                                <h3 className="text-xl md:text-2xl font-semibold">5000€</h3>
                                                <span className="text-xs">Poupança Média</span>
                                            </div>
                                            <div className='card'>
                                                <h3 className="text-xl md:text-2xl font-semibold">10M€</h3>
                                                <span className="text-xs">Poupança Acumulada</span>
                                            </div>
                                        </div>

                                        <div className='flex gap-2 items-center'>
                                            <div className="flex items-center">
                                                <span className="text-yellow-500">★</span>
                                                <span className="text-yellow-500">★</span>
                                                <span className="text-yellow-500">★</span>
                                                <span className="text-yellow-500">★</span>
                                                <span className="text-yellow-500">★</span>
                                            </div>
                                            <span className="text-xs">Avaliação média dos nossos clientes</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="md:col-span-4 content-center">
                            <div className="card-white altura-obrg text-black flex flex-col justify-center py-10 md:p-4">
                                <div className='grid gap-4'>
                                    {status == "completed" ? (
                                        <>
                                            <div className='grid gap-4'>
                                                <FaCheckCircle style={{ fontSize: '38px', color: '#0D9F57' }} />
                                                <p className="text-lg font-bold">Documentos Validados!</p>
                                                <p className="text-sm font-normal">
                                                    Os seus documentos foram validados. Em breve, o responsável pelo seu negócio entrará em contato.
                                                </p>
                                                <p className="text-sm font-normal"><strong>Obrigado!</strong></p>
                                            </div>
                                        </>
                                    ) : status == "pending" ? (
                                        <>
                                            <div className='grid gap-1'>
                                                <p className='text-lg font-bold'>Documentos</p>
                                                <p className='text-sm font-normal'>Para avançar com o seu negócio, por favor envie os seguintes documentos:</p>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <Form
                                                    form={form}
                                                    className='form-de-envio-docs'
                                                    layout="vertical"
                                                    onFinish={handleSubmit}
                                                >


                                                    {data?.documentacao?.estado_documentos?.find(doc => doc.nome === "Documento de Identificação")?.status !== 'accepted' && tipoDocumento?.documento_identificacao && (
                                                        <div className="grid gap-2 content-start">
                                                            <p className='font-semibold'>Tipo de Documento de Identificação</p>
                                                            <Form.Item
                                                                name="tipoDocumento"
                                                                className='icon-style'
                                                                rules={[{ required: true, message: 'Por favor selecione o tipo de documento!' }]}
                                                            >
                                                                <Select placeholder="Selecione o tipo de documento">
                                                                    <Option value="cc">Cartão de Cidadão (CC)</Option>
                                                                    <Option value="bi">Bilhete de Identidade (BI)</Option>
                                                                    <Option value="passaporte">Passaporte</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    )}


                                                    {/* Campo documento_identificacao (file) */}
                                                    <div className="grid gap-2 content-start ">

                                                        <div className='flex gap-2 items-center'>
                                                            <p className='font-semibold'>Documento de Identificação</p>
                                                            {data?.documentacao?.estado_documentos?.find(doc => doc.nome === "Documento de Identificação")?.status === 'accepted' && (
                                                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                                                            )}

                                                        </div>

                                                        {data?.documentacao?.estado_documentos?.find(doc => doc.nome === "Documento de Identificação")?.status !== 'accepted' && tipoDocumento?.documento_identificacao && (
                                                            <Form.Item
                                                                name="documentoIdentificacao"
                                                                valuePropName="file"
                                                                className='icon-style'
                                                                rules={[{ required: true, message: 'Por favor faça upload do documento de identificação!' }]}
                                                            >
                                                                <Upload multiple beforeUpload={() => false}>
                                                                    <Button className='shadow-none font-bold text-black bg-white' icon={<UploadOutlined className='text-black' />}>
                                                                        Carregar Documento
                                                                    </Button>
                                                                </Upload>
                                                            </Form.Item>
                                                        )}

                                                    </div>

                                                    {/* Se for Empresa, exibe campos específicos */}
                                                    {tipoDeNegocio.tipo_contrato == "empresa" ? (
                                                        <>
                                                            {/* Campo certidao_permanente (texto) */}
                                                            <div className="grid gap-2 content-start">
                                                                <div className='flex gap-2 items-center'>
                                                                    <p className='font-semibold'>Código da Certidão Permanente</p>
                                                                    {data?.documentacao.estado_documentos?.find(doc => doc.nome === "Certidão Permanente")?.status === 'accepted' && (
                                                                        <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                                                                    )}
                                                                </div>
                                                                {data?.documentacao?.certidao_permanente?.find(doc => doc.nome === "Certidão Permanente")?.status !== 'accepted' && tipoDocumento?.certidao_permanente && (

                                                                // {tipoDocumento?.certidao_permanente && (
                                                                    <Form.Item
                                                                        className='icon-style'
                                                                        // label="Certidão Permanente"
                                                                        name="certidaoPermanente"
                                                                        rules={[{ required: true, message: 'Por favor insira o código da certidão permanente!' }]}
                                                                    >
                                                                        <Input placeholder="Insira o código da certidão permanente" />
                                                                    </Form.Item>
                                                                )}
                                                            </div>
                                                            {/* Campo documento_conducao (file) */}
                                                            <div className="grid gap-2 content-start">
                                                                <div className='flex gap-2 items-center'>
                                                                    <p className='font-semibold'>Carta de Condução</p>
                                                                    {data?.documentacao.estado_documentos?.find(doc => doc.nome === "Carta de Condução")?.status === 'accepted' && (
                                                                        <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                                                                    )}
                                                                </div>
                                                                {data?.documentacao?.estado_documentos?.find(doc => doc.nome === "Carta de Condução")?.status !== 'accepted' && tipoDocumento?.documento_conducao && (
                                                                    // {(tipoDocumentoRejeitado?.tipo_documento?.includes("Carta de Condução") || !tipoDocumentoRejeitado?.tipo_documento) && (
                                                                    <Form.Item
                                                                        // label="Documento de Condução"
                                                                        name="documentoConducao"
                                                                        className='icon-style'
                                                                        valuePropName="file"
                                                                        rules={[{ required: true, message: 'Por favor faça upload da carta de condução!' }]}
                                                                    >
                                                                        <Upload multiple beforeUpload={() => false}>
                                                                            <Button className='shadow-none font-bold text-black bg-white' icon={<UploadOutlined />}>Carregar Documento</Button>
                                                                        </Upload>
                                                                    </Form.Item>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* Campo documento_morada (file) */}

                                                            <div className="grid gap-2 content-start">
                                                                <div className='flex gap-2 items-center'>
                                                                    <p className='font-semibold'>Comprovativo de Morada</p>
                                                                    {data?.documentacao.estado_documentos?.find(doc => doc.nome === "Comprovativo de Morada")?.status === 'accepted' && (
                                                                        <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                                                                    )}
                                                                </div>


                                                                {data?.documentacao?.estado_documentos?.find(doc => doc.nome === "Comprovativo de Morada")?.status !== 'accepted' && tipoDocumento?.documento_morada && (
                                                                    <Form.Item
                                                                        name="documentoMorada"
                                                                        valuePropName="file"
                                                                        className='icon-style'
                                                                        rules={[{ required: true, message: 'Por favor faça upload do comprovativo de morada!' }]}
                                                                    >
                                                                        <Upload multiple beforeUpload={() => false}>
                                                                            <Button className='shadow-none font-bold text-black bg-white' icon={<UploadOutlined />}>Carregar Documento</Button>
                                                                        </Upload>
                                                                    </Form.Item>
                                                                )}

                                                            </div>

                                                            {/* Campo documento_conducao (file) */}
                                                            <div className="grid gap-2 content-start">
                                                                <div className='flex gap-2 items-center'>
                                                                    <p className='font-semibold'>Carta de Condução</p>
                                                                    {data?.documentacao.estado_documentos?.find(doc => doc.nome === "Carta de Condução")?.status === 'accepted' && (
                                                                        <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                                                                    )}
                                                                </div>
                                                                {data?.documentacao?.estado_documentos?.find(doc => doc.nome === "Carta de Condução")?.status !== 'accepted' && tipoDocumento?.documento_conducao && (
                                                                    <Form.Item
                                                                        name="documentoConducao"
                                                                        valuePropName="file"
                                                                        className='icon-style'
                                                                        rules={[{ required: true, message: 'Por favor faça upload da carta de condução' }]}
                                                                    >
                                                                        <Upload multiple beforeUpload={() => false}>
                                                                            <Button className='shadow-none font-bold text-black bg-white' icon={<UploadOutlined />}>Carregar Documento</Button>
                                                                        </Upload>
                                                                    </Form.Item>
                                                                )}

                                                            </div>
                                                        </>
                                                    )}

                                                    <hr></hr>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className='shadow-none font-bold text-black bg-white'>
                                                            Enviar Documentos
                                                        </Button>
                                                    </Form.Item>

                                                </Form>
                                            </div>
                                        </>
                                    ) : status == "validation" ? (
                                        <>
                                            <div className='grid gap-4'>
                                                <FaClock style={{ fontSize: '38px', color: '#eab308' }} />
                                                <p className="text-lg font-bold">Pendente de validação!</p>
                                                <p className="text-sm font-normal">
                                                    Os seus documentos foram <strong>enviados com sucesso!</strong> Em breve, o responsável pelo seu negócio entrará em contato.
                                                </p>
                                                <p className="text-sm"><strong>Obrigado!</strong></p>
                                            </div>
                                        </>
                                    ) : status == "rejected" ? (
                                        <>
                                            <div className='grid gap-4'>
                                                <IoMdCloseCircle style={{ fontSize: '38px', color: '#F44336' }} />
                                                <p className="text-lg font-bold">Documentos não aprovados!</p>
                                                <p className="text-sm font-normal">
                                                    Os seus documentos <strong>não foram aprovados!</strong>
                                                </p>
                                                <p className="grid gap-1 text-sm font-normal">
                                                    <strong>Documentos Rejeitados: </strong>

                                                    {estadosDocumentos.map((item) => (
                                                        <span key={item.nome}>{item.nome}</span>
                                                    ))}


                                                </p>
                                                <p className="grid gap-1 text-sm font-normal"><strong>Motivo: </strong>{tipoDeNegocio.motivo}</p>
                                                <p className="text-sm font-normal">Por favor, submeta novamente. <strong>Obrigado!</strong></p>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className='shadow-none font-bold text-black bg-white'
                                                    onClick={() => SetStatus('pending')}
                                                >
                                                    Enviar Novamente
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                            <div className='grid gap-4'>
                                                <IoMdCloseCircle style={{ fontSize: '38px', color: '#F44336' }} />
                                                <p className="text-lg font-bold">Ocorreu algum erro!</p>
                                                <p className="text-sm font-normal">
                                                    Contacte o responsável do seu processo. <strong>Obrigado!</strong>
                                                </p>
                                            </div>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
